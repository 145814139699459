<template>
  <div
    class="ProgressBar">
    <div
      class="__track">
      <div
        class="__progress"
        :style="computedProgressStyle">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ProgressBar',

  props: {
    progress: {
      type: Number,
      required: true,
    },
  },

  computed: {
    computedProgressStyle() {
      return {
        width: `${Math.floor(this.progress)}%`,
      };
    },
  },
};
</script>


<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/_variables';

$progress-bar-height: 4px;

.ProgressBar {
  position: relative;
  width: 100%;
}

.__track {
    display: block;
    position: relative;
    width: 100%;
    height: $progress-bar-height;
    border-radius: math.div($progress-bar-height, 2);
    background-color: $light;
    overflow: hidden;
}

.__progress {
    width: 0%;
    height: 100%;
    background-color: $light-blue;
    transition: width 1s ease;
}
</style>
